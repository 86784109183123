/**
 * Created by Jean Rumeau on 25/06/2015.
 */

$(function() {
    var grid = $("#grid");

    if (grid.length> 0) {
        grid.bootgrid({
            ajax: true,
            url: grid.data('url'),
            ajaxSettings: {
                method: "GET",
                cache: false
            },
            formatters: {
                "commands": function (column, row) {
                    if (grid.data('crud') == true) {
                        return "<a href=\"" + grid.data('url') + "/" + row.id + "/edit\" class=\"btn btn-sm btn-default\" data-row-id=\"" + row.id + "\"><span class=\"glyphicon glyphicon-pencil\"></span></a> " +
                            "<a href=\"" + grid.data('url') + "/" + row.id + "/destroy\" class=\"btn btn-sm btn-danger\" data-row-id=\"" + row.id + "\"><span class=\"glyphicon glyphicon-trash\"></span></a>";
                    }

                    return '';
                },
                "nameDescription": function (column, row) {
                    return row.name + '<br /><small>' + row.description + '</small>';
                }
            }
        })
    }
});
